<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>

					<a-form-item label="模块名称" name="name" class="ui-form__item">
						<a-input v-model:value="formState.name" placeholder="请输入模块名称"></a-input>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button type="primary" @click="onAdd">新增模块</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<cTable ref="cTable" isPage isRequest :searchData="searchData" :requestFun="getQuestionModuleList" :columns="columns">
					<template #bodyCell="{column, record}">
						<template v-if="column.key === 'image'">
							<a-image :src="record.image" style="width: 60px;"></a-image>
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
							  <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
							  </a-button>
							  <template #overlay>
							    <a-menu>
							      <div @click="onEdit(record)">
							        <a-menu-item>
							          编辑
							        </a-menu-item>
							      </div>
								  <div v-if="record.isDisabled" @click="onDisbaled(record)">
								    <a-menu-item>
								      启用
								    </a-menu-item>
								  </div>
								  <div v-else @click="onDisbaled(record)">
								    <a-menu-item>
								      禁用
								    </a-menu-item>
								  </div>
							      <div @click="onDelete(record)">
							        <a-menu-item>
							          删除
							        </a-menu-item>
							      </div>
							    </a-menu>
							  </template>
							</a-dropdown>
						</template>
					</template>
				</cTable>
			</div>
		</a-spin>
		<a-modal v-model:visible="modalVisible" :title="modelRef.id ? '编辑' : '新增'" width="800px" @cancel="onCancel" @ok="onSubmit">
			<a-spin :spinning="loading">
				<a-form ref="formRef" :model="modelRef" :labelCol="{span: 5}" :wrapperCol="{span: 16}">
					<a-form-item label="模块名称" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input v-model:value="modelRef.name" placeholder="请输入模块名称"></a-input>
					</a-form-item>
					
					<a-form-item label="模块Icon" name="image" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议宽高比: 1:1">
						<a-upload v-model:file-list="fileList" list-type="picture-card"
							action="/admin/ajaxUpload.do" accept="image/*"
							@change="onUploadChange"
						>
							<div v-if="fileList.length < 1">
								<Icon icon="PlusOutlined"></Icon>
								<div style="margin-top: 8px">Upload</div>
							</div>
						</a-upload>
					</a-form-item>
					
					<a-form-item label="优先级" name="sort" :rules="[{required: true, message: '必填项不允许为空'}]" extra="值越大越靠前">
						<a-input-number v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-radio-group v-model:value="modelRef.isDisabled">
							<a-radio :value="0">启用</a-radio>
							<a-radio :value="1">禁用</a-radio>
						</a-radio-group>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import { getQuestionModuleList, getQuestionModuleDetail, saveQuestionModule, updateQuestionModule, deleteQuestionModule, disabledQuestionModule } from '@/service/modules/operation.js';
	export default {
		components: {
			Icon,
			cTable
		},
		data() {
			return {
				loading: false,
				formState: {
					name: '',
					time: [],
					couponType: 2,
					// organizationId: 0,
					status: 0
				},
				searchData: {},
				columns: [{
					title: '模块名称',
					dataIndex: 'name',
					width: 100
				}, {
					title: 'icon',
					key: 'image',
					width: 100
				}, {
					title: '优先级',
					dataIndex: 'sort',
					width: 100
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					width: 50
				}],
				modalVisible: false,
				modelRef: {
					isDisabled: 1
				},
				fileList: []
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			getQuestionModuleList: getQuestionModuleList,
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData(true);
			},
			async getData(isRef) {
				if (this.isRef) {
					this.$refs.cTable.refQuery()
				} else {
					this.$refs.cTable.toQuery();
				}
			},
			onAdd() {
				this.modelRef = {
					isDisabled: 1
				}
				this.modalVisible = true;
			},
			onEdit(item) {
				this.modelRef = {
					...item
				}
				if (item.image) {
					this.fileList = [{
						uid: 0,
						name: item.image,
						url: item.image
					}];
				}
				this.modalVisible = true;
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				this.loading = true;
				let ret;
				if (postData.id) {
					ret = await updateQuestionModule(postData);
				} else {
					ret = await saveQuestionModule(postData);
				}
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功');
					this.getData();
					this.onCancel();
				}
			},
			onCancel() {
				this.$refs.formRef.resetFields();
				this.modelRef = {isDisabled: 1}
				this.modalVisible = false;
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除该模块吗？',
					onOk: async ()=>{
						this.loading = true;
						let ret = await deleteQuestionModule({
							id: item.id
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('删除成功');
							this.getData(true);
						}
					}
				})
			},
			onDisbaled(item) {
				this.$confirm({
					title: '提示',
					content: `确定${item.isDisabled ? '启用' : '禁用'}该模块吗？`,
					onOk: async ()=>{
						this.loading = true;
						let ret = await disabledQuestionModule({
							id: item.id,
							isDisabled: item.isDisabled ? 0 : 1
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.getData();
						}
					}
				})
			},
			onUploadChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.fileList = info.fileList;
					const $fileList = [...this.fileList];
					this.fileList = $fileList.map(file => {
						if (file.response) {
							this.modelRef.image = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>